.filter-section {
    display: flex;
    align-items: center;
    justify-content: center;
}
.filter-item {
    padding: 5px;
    margin: 0 5px;
}

.filter-search-bar {
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
}
.filter-search-bar input {
    height: 35px;
    text-indent: 10px;
    font-size: 14px;
    outline: none;
    border-radius: 12px;
    border: 1px solid #ccc;
    transition: all 0.3s ease;
}
.filter-search-bar input:focus {
    border-color: #0099ff;
}
.dark .filter-search-bar input {
    background-color: #333333;
    border-color: #555555;
    color: #ffffff;
}
.dark .filter-search-bar input:focus {
    border-color: #0099ff;
}
.filter-search-clear-button {
    height: 35px;
    padding: 6px 6px;
    font-size: 14px;
    background-color: #e5e5e5;
    color: #333;
    cursor: pointer;
    border: none;
    border-radius: 12px;
    transition: all 0.3s ease;
}
.filter-search-clear-button:hover {
    background-color: #c7c6c6;
    color: #333;
}
.dark .filter-search-clear-button {
    background-color: #333333;
    color: #ffffff;
}
.dark .filter-search-clear-button:hover {
    background-color: #454545;
    color: #ffffff;
}
.filter-search-dropdown {
    position: absolute;
    top: 100%;
    width: 95%;
    max-height: 200px;
    overflow-y: auto;
    color: #333;
    background: #fff;
    font-size: 14px;
    border-radius: 12px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
}
.filter-search-dropdown > div {
    padding: 8px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.filter-search-dropdown > div:hover {
    background-color: #e5e5e5;
}
.dark .filter-search-dropdown {
    background-color: #333333;
    border-color: #555555;
    color: #ffffff;
}
.dark .filter-search-dropdown > div:hover {
    background-color: #454545;
}
.selected {
    text-decoration: underline;
}

.distance-filter .distance-label {
    font-size: 14px;
    position: relative;
}
.distance-filter .distance-select {
    height: 35px;
    padding: 6px 6px;
    margin-left: 5px;
    font-size: 14px;
    background-color: #e5e5e5;
    color: #333;
    cursor: pointer;
    border: none;
    border-radius: 12px;
    transition: all 0.3s ease;
}
.distance-filter .distance-select:hover {
    background-color: #c7c6c6;
}
.distance-filter .distance-select:focus {
    outline: none;
}
.dark .distance-filter .distance-select {
    background-color: #333333;
    color: #ffffff;
}
.dark .distance-filter .distance-select:hover {
    background-color: #454545;
    color: #ffffff;
}

.public-access-filter label {
    display: flex;
    align-items: center;
    font-size: 14px;
}
.public-access-filter input[type="checkbox"] {
    margin-top: 5px;
    margin-left: 5px;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .filter-search-bar input {
        font-size: 13px;
    }
    .filter-search-clear-button {
        font-size: 13px;
        padding: 5px 5px;
    }
    .distance-filter .distance-label {
        font-size: 13px;
    }
    .distance-filter .distance-select {
        font-size: 13px;
        padding: 5px 5px;
    }
    .public-access-filter label {
        font-size: 13px;
    }
}
@media (max-width: 1050px) {
    .filter-search-bar input {
        font-size: 12px;
    }
    .filter-search-clear-button {
        font-size: 12px;
        padding: 4px 4px;
    }
    .distance-filter .distance-label {
        font-size: 12px;
    }
    .distance-filter .distance-select {
        font-size: 12px;
        padding: 4px 4px;
    }
    .public-access-filter label {
        font-size: 12px;
    }
}
@media (max-width: 800px) {
    .filter-search-bar input {
        font-size: 11px;
    }
    .filter-search-clear-button {
        font-size: 11px;
        padding: 3px 3px;
    }
    .distance-filter {
        margin-left: -10px;
    }
    .distance-filter .distance-label {
        font-size: 11px;
        display: None;
    }
    .distance-filter .distance-select {
        font-size: 11px;
        padding: 3px 3px;
    }
    .public-access-filter {
        margin-left: -5px;
    }
    .public-access-filter label {
        font-size: 11px;
    }
}
@media (max-width: 500px) {
    .filter-item {
        padding: 2px 1px;
        margin: 0 1px;
    }
    .filter-search-bar input {
        height: 25px;
        max-width: 35px;
        border-radius: 6px;
        text-indent: 5px;
        font-size: 8px;
        margin-left: 5px;
    }
    .filter-search-dropdown {
        width: 150%;
        max-height: 200px;
        font-size: 8px;
        border-radius: 6px;
    }
    .filter-search-dropdown > div {
        padding: 4px 5px;
    }
    .filter-search-clear-button {
        height: 25px;
        width: 25px;
        font-size: 8px;
        padding: 1px 1px;
        border-radius: 6px;
    }
    .distance-filter .distance-label {
        font-size: 8px;
        height: 25px;
        display: none;
    }
    .distance-filter .distance-select {
        height: 25px;
        font-size: 8px;
        padding: 1px 1px;
        border-radius: 6px;
    }
    .public-access-filter label {
        font-size: 8px;
        padding-left: 5px;
    }
}

.navbar {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    padding: 5px 30px 5px 20px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #fafafa;
    border-bottom: 1px solid #ccc;
    z-index: 4;
}
.dark .navbar {
    background-color: #1d1d1d;
    border-bottom: 1px solid #555555;
}

.logo-container {
    display: flex;
    align-items: center;
}
.logo {
    width: 50px;
    height: 50px;
    cursor: pointer;
}
.logo-title {
    font-size: 1.1em;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: all 0.1s ease;
}
.dark .logo-title {
    color: #ffffff;
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#checkbox {
    display: none;
}
.toggle {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    background: linear-gradient(to bottom, #6ec8ff, #3077a7);
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
#checkbox:checked + .toggle {
    background: linear-gradient(to bottom, #004b7a, #002138);
}
/* Sun and Moon SVG styles */
.sun, .moon {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 50%;
    transform-origin: center 120%;
    transition: all 0.5s ease-in-out;
}
.sun {
    top: 12%;
    --rotation: 0deg;
    transform: translateY(-50%) rotate(var(--rotation));
    transform: translateX(-50%) rotate(var(--rotation));
    fill: #fbdb4c;
}
.moon {
    top: 12%;
    --rotation: 180deg;
    transform: translateY(0%) rotate(var(--rotation));
    fill: #e2e2e2;
}
#checkbox:checked + .toggle .sun {
    --rotation: 180deg;
}
#checkbox:checked + .toggle .moon {
    --rotation: 360deg;
    transform: translateX(-50%) rotate(var(--rotation));
}

@media (max-width: 1200px) {
    .logo-title {
      font-size: 1em;
    }
}
@media (max-width: 1050px) {
    .logo-title {
      font-size: 1em;
    }
}
@media (max-width: 500px) {
    .navbar {
        padding: 5px 10px 10px 0px;
    }
    .logo {
        width: 35px;
        height: 35px;
    }
    .logo-title {
        font-size: 0.7em;
    }
    .toggle {
        height: 30px;
        width: 30px;
        border-radius: 30px;
    }
    .sun, .moon {
        width: 20px;
        height: 20px;
        left: 50%;
    }
}

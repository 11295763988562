.grid-list-component-container {
    width: 90vw;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 10px;
}

.grid-list-controls-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -1px;
}

.pagination-container {
    flex: 1;
    display: flex;
    justify-content: center;
}
.pagination {
    display: flex;
    align-items: center;
    padding: 5px 0px 0px 0px;
    gap: 10px;
}
.pagination button {
    font-size: 14px;
    background-color: #414141;
    color: #ffffff;
    border: none;
    border-radius: 20px;
    padding: 9px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
}
.pagination button:hover {
    background-color: #4e4e4e;
}
.pagination button:disabled {
    background-color: #a5a5a5;
    cursor: default;
}
.dark .pagination button {
    background-color: #ddd;
    color: #000000;
}
.dark .pagination button:hover {
    background-color: #bebebe;
}
.dark .pagination button:disabled {
    background-color: #e8e8e8;
    color: #a3a3a3;
}

.info-container {
    position: relative;
    display: flex;
    align-items: center;
}
.info-button {
    background-color: #414141;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
}
.info-button:hover {
    background-color: #4e4e4e;
}
.dark .info-button {
    background-color: #ddd;
    color: #000000;
}
.dark .info-button:hover {
    background-color: #bebebe;
}
.info-dropdown {
    position: absolute;
    margin-top: 5px;
    top: 100%;
    right: 0;
    width: 250px;
    max-height: 20vh;
    display: none;
    overflow-y: auto;
    padding: 10px;
    z-index: 3;
    color: #333;
    background: #fff;
    font-size: 14px;
    border-radius: 12px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
}
.info-button:hover + .info-dropdown, 
.info-dropdown:hover {
    display: block;
}
.info-dropdown p {
    margin-bottom: 0.5rem;
}
.dark .info-dropdown {
    background-color: #333333;
    border-color: #555555;
    color: #ffffff;
}

.places-grid-list-container {
    width: 100%;
    min-height: 65vh;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
    justify-items: center;
    margin: 10px 0px 0px 0px;
    transition: all 0.1s ease;
}

.Greenspaces.place-item  { border-color: #62b462; }
.SportsAndRec.place-item  { border-color: #e59d69; }
.LandmarksAndCulturalAndEntertainment.place-item { border-color: #bc6de1; }
.dark .Greenspaces.place-item  { border-color: #369f36; }
.dark .SportsAndRec.place-item  { border-color: #ff8e3d; }
.dark .LandmarksAndCulturalAndEntertainment.place-item { border-color: #ac46db; }
.place-item {
    width: 260px;
    height: 240px;
    overflow-y: auto;
    margin-bottom: 2vh;
    padding: 20px;
    border: 3px solid #ddd;
    border-radius: 8px;
    border-color: #ccc;
    background-color: #f0f0f0;
    color: #000000;
    transition: all 0.5s ease;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
}
.dark .place-item {
    background-color: #333333;
    color: #ffffff;
}
.place-item h2 {
    font-size: 1rem;
    font-weight: bold;
    transition: all 0.1s ease;
}
.place-item h3 {
    font-size: 0.9rem;
    font-weight: normal;
    transition: all 0.1s ease;
}
.divider {
    height: 2px;
    background-color: #ccc;
    transition: all 0.1s ease;
}
.info-pair {
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.1s ease;
}
.label-wrapper {
    display: flex;
    align-items: center;
}
.icon {
    width: 15px;
    height: 15px;
    fill: #000000;
}
.dark .icon {
    fill: #ffffff;
}

@media (max-width: 1200px) {
    .place-item {
        width: 230px;
        height: 220px;
    }
    .place-item h2 {
        font-size: 0.9rem;
    }
    .place-item h3 {
        font-size: 0.8rem;
    }
    .info-pair {
        font-size: 0.7rem;
    }
    .icon {
        width: 14px;
        height: 14px;
    }
    .pagination button {
        padding: 9px 18px;
        font-size: 13px;
    }
}
@media (max-width: 1050px) {
    .places-grid-list-container {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 800px) {
    .place-item {
        width: 200px;
        height: 200px;
    }
    .places-grid-list-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 500px) {
    .pagination {
        padding: 5px 0px 0px 0px;
        gap: 7px;
        font-size: 11px;
    }
    .pagination-container .pagination button {
        display: flex;
        text-align: center;
        font-size: 8px;
        border-radius: 20px;
    }
    .info-button {
        width: 18px;
        height: 18px;
        font-size: 8px;
    }
    .info-dropdown {
        margin-top: 3px;
        width: 200px;
        padding: 7px;
        font-size: 10px;
        border-radius: 8px;
    }
    .place-item {
        width: 130px;
        height: 130px;
        padding: 7px;
        border: 1px solid #ddd;
    }
    .divider {
        height: 1px;
    }
    .place-item h2 {
        font-size: 0.6rem;
    }
    .place-item h3 {
        font-size: 0.5rem;
    }
    .info-pair {
        font-size: 0.4rem;
    }
    .icon {
        width: 10px;
        height: 10px;
    }
    .pagination button {
        padding: 9px 18px;
        font-size: 13px;
    }
    .places-grid-list-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

.google-map:focus {
    outline: none;
}

.info-window:focus {
    outline: none;
}

.place-popup h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 5px 0;
}
.place-popup h3 {
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 5px 0;
}
.info-pair {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin: 5px 0 0 0;
}
.label-icon-wrapper {
    display: flex;
    align-items: center;
}
.divider {
    height: 2px;
    background-color: #ccc;
    margin: 10px 0;
}
.icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: middle;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.App.light {
  --bg-color: #fafafa;
  --text-color: #000000;
  --info-bg-color: #f0f0f0;
  --info-text-color: #000000;
  --input-bg: #ffffff;
  --input-border: #ccc;
  --input-text: #000000;
  --search-button-bg: #4caf50;
  --search-button-text: #ffffff;
  --search-button-bg-hover: #54c359;
  --search-button-text-hover: #ffffff;
  --toggle-map-view-button-bg: #414141;
  --toggle-map-view-button-bg-hover: #414141;
  --toggle-map-view-button-bg-click: #343434;
  --toggle-map-view-button-text-color: #ffffff;
  --search-button-active-color: #4b874b
}
.App.dark {
  --bg-color: #1d1d1d;
  --text-color: #ffffff;
  --info-bg-color: #333333;
  --info-text-color: #ffffff;
  --input-bg: #333333;
  --input-border: #555555;
  --input-text: #ffffff;
  --search-button-bg: #019801;
  --search-button-text: #ffffff;
  --search-button-bg-hover: #00b200;
  --search-button-text-hover: #ffffff;
  --toggle-map-view-button-bg: #ddd;
  --toggle-map-view-button-bg-hover: #ddd;
  --toggle-map-view-button-bg-click: #bebebe;
  --toggle-map-view-button-text-color: #000000;
  --search-button-active-color: #4b874b
}
html, body, .App {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.background-image {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-size: cover;
  background-position: center;
  transition: 0.3s ease, color 0.3s ease;
}

.error-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  background-color: white;
  padding: 20px;
  border: 2px solid #ed4242;
  border-radius: 10px;
  z-index: 5;
}
.error-close-button {
  position: absolute;
  top: 4px;
  right: 4px;
  border: none;
  background: none;
  cursor: pointer;
}
.error-close-button svg {
  width: 12px;
  height: 12px;
  color: #333;
}

.search-bar-launch-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95vh;
  z-index: 2;
}
.captcha-container {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}
.search-filter-bar-main-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 60px;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 2;
}
.search-bar-launch-container .input-container {
  width: 350px;
  position: relative;
}
.search-filter-bar-main-container .input-container {
  min-width: 20%;
  max-width: 100%;
  position: relative;
}
.input {
  width: 100%;
  height: 40px;
  padding-right: 40px;
  padding-left: 15px;
  border-radius: 25px;
  border: 1px solid var(--input-border);
  background-color: var(--input-bg);
  color: var(--input-text);
  font-size: 14px;
  outline: none;
  transition: all 0.1s ease;
}
.input:focus {
  border-color: #0099ff;
}
.search-button {
  background-color: var(--search-button-bg);
  border: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.search-icon {
  width: 17px;
  height: auto;
  fill: var(--search-button-text);
}
.search-button:hover {
  background-color: var(--search-button-bg-hover);
  color: var(--search-button-text-hover);
}
.search-button:active {
  background-color: var(--search-button-active-color);
}

div.pac-container {
  margin-top: 4px;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
}
div.pac-item {
  padding: 5px 10px;
  font-size: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}
div.pac-item-query {
  font-size: 10px;
  font-weight: bold;
}
div.pac-item:hover {
  background-color: #e5e5e5;
}

.info-section {
  position: relative;
  width: 100vw;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--info-bg-color);
  color: var(--info-text-color, #000000);
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
  z-index: 1;
}
.info-section .places-shown {
  position: absolute;
  top: 0px;
  right: 30px;
}
.info-section h2 {
  font-size: 20px;
  transition: all 0.1s ease;
}
.info-section p {
  margin: 8px 0;
  font-size: 15px;
  transition: all 0.1s ease;
}

.map-container {
  position: fixed;
  bottom:0px;
  width: 100%;
  overflow: hidden;
}

.footer-container {
  width: 100%;
  z-index: 2;
}

.toggle-map-view-button {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--toggle-map-view-button-bg);
  color: var(--toggle-map-view-button-text-color);
  font-size: 14px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  transition: all 0.3s ease;
}
.toggle-map-view-button:hover {
  background-color: var(--toggle-map-view-button-bg-hover);
  transform: translateX(-50%) scale(1.05);
}
.toggle-map-view-button:active {
  background-color: var(--toggle-map-view-button-bg-click);
  transform: translateX(-50%) scale(0.95);
}

@media (max-width: 1200px) {
  .info-section h2 {
    font-size: 19px;
  }
  .info-section p {
    font-size: 14px;
  }
  .toggle-map-view-button {
    padding: 9px 18px;
    font-size: 13px;
}
}
@media (max-width: 1050px) {
  .input {
    font-size: 13px;
  }
  .info-section h2 {
    font-size: 18px;
  }
}
@media (max-width: 800px) {
  .info-section h2 {
    font-size: 16px;
  }
  .info-section p {
    font-size: 13px;
  }
}
@media (max-width: 500px) {
  .search-filter-bar-main-container {
    padding-top: 56px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .search-bar-launch-container .input-container {
    width: 70%;
  }
  .input {
    height: 35px;
    font-size: 10px;
    padding-right: 35px;
  }
  .search-button {
    height: 25px;
    width: 25px;
  }
  .search-icon {
    height: 13px
  }
  div.pac-item {
    padding: 1px 2px;
  }
  .info-section {
    padding-left: 10px;
    padding-right: 10px;
  }
  .info-section h2 {
    font-size: 12px;
  }
  .info-section p {
    font-size: 10px;
  }
  .info-section .places-shown {
    right: 10px;
  }
  .toggle-map-view-button {
    font-size: 12px;
  }
  .footer-container {
    font-size: 10px;
  }
}